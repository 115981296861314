import React from 'react';

const ProductBenefits = props => {

  return (
    <section className="product-benefits">
      <div className="product-benefits-group">
        <div className="product-benefit-1-tagline">
          We know what it's like to look for opportunities in STEM so we built STEMbase.org
        </div>
        <div className="product-benefit-1-image"></div>
        <div className="product-benefit-2-tagline">
          STEMbase.org let's you quickly search, organize, and save the opportunites that fit best to your interests.
        </div>
        <div className="product-benefit-2-image"></div>
        <div className="product-benefit-3-tagline">
          Found an opportunity and we don't have it listed? You can contribute it and help make STEM more accesible to everyone.
        </div>
        <div className="product-benefit-3-image"></div>
      </div>
    </section>
  )
};

export default ProductBenefits;
