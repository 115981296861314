import React from 'react';
// import Button from "./Button";
import SignUp from "./SignUp"

// import { Link } from "gatsby"


const ValueProp = props => {
  return (
    <section className="value-prop">
      <div className="value-prop-content">
        <div className="main-message">
          <h1>Find your next STEM opportunity</h1>
          <p className="main-subtitle">
            You want to grow and develop as a scientist. We want to help you get there.
          </p>
          <h3> Sign up to receive updates about our launch </h3>
          <SignUp/>
          {/* <Link to="/search"> 
            <Button label="Get Started"   />
          </Link> */}
          
        </div>
        <div className="main-photo"></div>
      </div>
    </section>
  )
};

export default ValueProp;
