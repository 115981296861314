import React from 'react';
// import Button from "./Button";

import SignUp from "./SignUp"

const CallToAction = props => {

  return (
    <section className="cta">
      <h1>Get ready for your next great opportunity</h1>
      {/* <Button label="Get Started" /> */}
      <h3> Sign up to receive updates about our launch </h3>
      <SignUp/>
    </section>
  )
};

export default CallToAction;
